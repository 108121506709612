<template>
  <admin>
    <page-header>
      <template v-slot:action>
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          large
          color="primary"
          exact
          :to="{ name: 'infobip-account.create' }"
        >
          <v-icon small left>mdi-plus-circle</v-icon>
          <span v-text="'Add New'"></span>
        </v-btn>
      </template>
    </page-header>

    <v-card>
      <toolbar-menu
        ref="toolbar"
        :filter-select-dealer="true"
        :items.sync="tabletoolbar"
        @update:search="setSearch"
        @update:dealer="handleFilterDealer"
      ></toolbar-menu>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="resources.headers"
          :loading="loading"
          :items="items"
          :options.sync="options"
          :server-items-length="total"
          :items-per-page="5"
          color="primary"
          item-key="id"
          class="text-no-wrap"
        >
        <template v-slot:item.id="{ item }">
            <div class="text-no-wrap">
              <v-btn
                :to="{ name: 'infobip-account.edit', params: { id: item.id } }"
                text
                color="primary"
                icon
              >
                {{ item.id }}
              </v-btn>
            </div>
          </template>
          <template v-slot:item.dealer="{ item }">
            <span v-if="item.dealer">{{ item.dealer.name }}</span>
            <span v-else="item.dealer"><strong>HCPI</strong></span>
          </template>
          <template v-slot:item.created_at="{ item }">
            <small>{{ item.created_at ? formatDate(item.created_at) : "" }}</small>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip class="ma-2" :color="item.status == 1 ? 'green' : 'red'" text-color="white">{{
              item.status == 1 ? "active" : "inactive"
            }}</v-chip>
          </template>
          <!-- Action buttons -->
          <template v-slot:item.action="{ item }">
            <div class="text-no-wrap">
              <!-- Edit -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    :to="{ name: 'infobip-account.edit', params: { id: item.id } }"
                    icon
                    v-on="on"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Edit'"></span>
              </v-tooltip>
              <!-- Edit -->
              <!-- Delete -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text icon v-on="on" @click="handleDeleteAccount(item.id)">
                    <v-icon small>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Delete'"></span>
              </v-tooltip>
              <!-- Delete -->
            </div>
          </template>
          <!-- Action buttons -->
        </v-data-table>
      </v-card-text>
    </v-card>
  </admin>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";

export default {
  data() {
    return {
      resources: {
        headers: [
          {
            text: "ID",
            align: "left",
            value: "id",
          },
          {
            text: "Dealer Name",
            align: "left",
            value: "dealer",
          },
          {
            text: "Sender ID",
            align: "left",
            value: "sender_id",
          },
          {
            text: "Status",
            align: "left",
            value: "status",
          },
          {
            text: "Date Created",
            align: "left",
            value: "created_at",
          },
          {
            text: "Actions",
            align: "center",
            value: "action",
            sortable: false,
            class: "muted--text text-no-wrap",
          },
        ],
      },
      options: {},
      items: [],
      total: 0,
      loading: false,
      tabletoolbar: {
        isSearching: false,
        search: null,
      },
    };
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.getItems(page, itemsPerPage);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      data: "infobipAccount/GET_INFOBIP_ACCOUNTS",
    }),
  },
  methods: {
    ...mapActions({
      getInfobipAccounts: "infobipAccount/list",
      deleteInfobipAccount: "infobipAccount/delete",
      showDialog: "dialog/show",
      hideDialog: "dialog/hide",
    }),
    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.getItems(page, itemsPerPage, e.target.value);
      this.$refs.toolbar.items.isSearching = false;
    }, 300),
    getItems(page, itemsPerPage, q = "", dealer = "") {
      this.loading = true;
      let data = {
        page,
        per_page: itemsPerPage,
        q,
      };
      if (dealer) {
        data.dealer = dealer;
      }
      this.getInfobipAccounts(data).then((data) => {
        this.items = this.data.data;
        this.total = itemsPerPage > 0 ? this.data.meta.total : this.data.data.length;
        this.loading = false;
        this.$refs.toolbar.items.isSearching = false;
      });
    },

    async handleDeleteAccount(itemId) {
      this.showDialog({
        title: trans("Delete Infobip Account"),
        text: trans("Are you sure you want to delete this account?"),
        buttons: {
          cancel: {
            text: trans("Cancel"),
            callback: () => {
              this.hideDialog();
            },
          },
          action: {
            color: "error",
            text: trans("Delete"),
            callback: () => {
              this.confirmDeleteAccount(itemId);
              this.hideDialog();
            },
          },
        },
      });
    },
    async confirmDeleteAccount(id) {
      await this.deleteInfobipAccount(id);
      this.getItems(1, 5, "");
    },
    handleFilterDealer(val) {
      this.getItems(1, 5, "", val);
    },

    formatDate(item) {
      return helpers.format_date(item);
    },
  },
};
</script>
